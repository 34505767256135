<template>

    

    <div style="padding:20px;">
        
        <v-card class="" elevation="" max-width="" >
            <v-card-text>

                <div class="linhaFormulario">

                    <div class="celularCampoForm">
                        <v-select color v-model="idstatusprojeto" @change="listarProjetos()" :items="listaStatusProjetos" label="Status Projeto"  item-text="titulo" item-value="id" return-object></v-select>
                    </div>

                    <div class="celularCampoForm">
                        <v-select color v-model="idprojeto" :items="listaProjetos" label="Projetos"  item-text="titulo" item-value="id"></v-select>
                    </div>

                    <div class="celularCampoForm">
                        <v-text-field label="Texto" placeholder="Parte do Texto da Atividade" v-model="texto" name="texto"></v-text-field>
                    </div>
                    
                    <div class="celularCampoForm">
                        <v-select color v-model="idusuario" :items="listaUsuarios" label="Responsavel"  item-text="nome" item-value="id"></v-select>
                    </div>

                    <div class="celularCampoForm">
                        <v-select color v-model="idusuario" :items="listaUsuarios" label="Cliente"  item-text="nome" item-value="id"></v-select>
                    </div>

                    <div class="celularCampoForm">
                        <v-select color v-model="atribuida" :items="listaAtribuidas" label="Atribuidas"  item-text="nome" item-value="id"></v-select>
                    </div>

                    <div class="celularCampoForm">
                        <v-select color v-model="concluido" :items="listaStatusConcluido" label="Concluidas"  item-text="nome" item-value="id"></v-select>
                    </div>

                </div>

                <div class="linhaFormulario">                
                    <div class="celularCampoForm">
                        <v-text-field label="Filtro" placeholder="Id Atividade" v-model="idatividadefiltro" name="filtro"></v-text-field>
                    </div>

                    <div class="celularCampoForm">
                        <v-text-field label="Id Solicitação Relacionada" placeholder="Id Solicitação Relacionada" v-model="idsolicitacaofiltro" name="texto"></v-text-field>
                    </div>

                     <div class="celularCampoForm">
                        <v-text-field type="date"  label="Dt Concluida Inicial"  v-model="dt_concluida_inicial" outlined color></v-text-field>
                    </div>

                    <div class="celularCampoForm">
                        <v-text-field type="date"  label="Dt Concluida Final"  v-model="dt_concluida_final" outlined color></v-text-field>
                    </div>

                    <div class="celularCampoForm">
                        <v-text-field type="time"  label="A partir do horario"  v-model="horario_inicial" outlined color></v-text-field>
                    </div>

                    <div class="celularCampoForm">
                        <v-text-field type="date"  label="Dt Estimativa Inicial"  v-model="dt_estimativa_inicial" outlined color></v-text-field>
                    </div>

                    <div class="celularCampoForm">
                        <v-text-field type="date"  label="Dt Estimativa Final"  v-model="dt_estimativa_final" outlined color></v-text-field>
                    </div>

                </div>
                <div class="linhaFormulario">

                   
           
                    <div class="celularCampoForm">
                        <v-checkbox label="Testadas"     v-model="atividades_testadas"    hide-details></v-checkbox>
                    </div>
           
                    <div class="celularCampoForm">
                        <v-checkbox label="Em Producao"    v-model="atividades_producao"    hide-details></v-checkbox>
                    </div>
           

                    

                </div>

                <div class="linhaFormulario" style="display:flex;flex-direction:row;justify-content:flex-end;">
                   
                    <v-container class="">
                       <v-row>
                         <v-col cols="12" md="8">
                                 <v-container class="">
                                    <v-row style="padding:0px!important;" >
                                            <v-col cols="12" md="2" style="padding:0px!important;" >
                                                    <a @click="setarFiltro({tipo:'P',dias:10})" :style="filtroRapidoSelecionado.tipo=='P' && filtroRapidoSelecionado.dias==10? 'color:green':''">Pend. -10d</a>
                                            </v-col>
                                            <v-col cols="12" md="2" style="padding:0px!important;" >
                                                    <a @click="setarFiltro({tipo:'P',dias:5})" :style="filtroRapidoSelecionado.tipo=='P' && filtroRapidoSelecionado.dias==5? 'color:green':''">Pend. -5d</a>
                                            </v-col>
                                            <v-col cols="12" md="2" style="padding:0px!important;" >
                                                    <a @click="setarFiltro({tipo:'P',dias:1})" :style="filtroRapidoSelecionado.tipo=='P' && filtroRapidoSelecionado.dias==1? 'color:green':''">Pend. Ontem</a>
                                            </v-col>
                                            <v-col cols="12" md="2" style="padding:0px!important;" >
                                                    <a @click="setarFiltro({tipo:'P',dias:0})" :style="filtroRapidoSelecionado.tipo=='P' && filtroRapidoSelecionado.dias==0? 'color:green':''">Pend. Hoje</a>
                                            </v-col>
                                            <v-col cols="12" md="4" style="padding:0px!important;" >
                                                    <a @click="setarFiltro({tipo:'P',dias:null})" :style="filtroRapidoSelecionado.tipo=='P' && filtroRapidoSelecionado.dias==null? 'color:green':''">Pendentes</a>
                                            </v-col>
                                    </v-row>

                                    <v-row style="padding:0px!important;" >
                                            <v-col cols="12" md="2" style="padding:0px!important;padding-top:4px;" >
                                                    <a @click="setarFiltro({tipo:'C',dias:10})" :style="filtroRapidoSelecionado.tipo=='C' && filtroRapidoSelecionado.dias==10? 'color:green':''" >Conc. -10d</a>
                                            </v-col>
                                            <v-col cols="12" md="2" style="padding:0px!important;padding-top:4px;" >
                                                    <a @click="setarFiltro({tipo:'C',dias:5})" :style="filtroRapidoSelecionado.tipo=='C' && filtroRapidoSelecionado.dias==5? 'color:green':''" >Conc. -5d</a>
                                            </v-col>
                                            <v-col cols="12" md="2" style="padding:0px!important;padding-top:4px;" >
                                                    <a @click="setarFiltro({tipo:'C',dias:1})" :style="filtroRapidoSelecionado.tipo=='C' && filtroRapidoSelecionado.dias==1? 'color:green':''" >Conc. Ontem</a>
                                            </v-col>
                                            <v-col cols="12" md="2" style="padding:0px!important;padding-top:4px;" >
                                                    <a @click="setarFiltro({tipo:'C',dias:0})" :style="filtroRapidoSelecionado.tipo=='C' && filtroRapidoSelecionado.dias==0? 'color:green':''" >Conc. Hoje</a>
                                            </v-col>
                                            <v-col cols="12" md="4" style="padding:0px!important;padding-top:4px;" >
                                                    <a @click="setarFiltro({tipo:'C',dias:null})" :style="filtroRapidoSelecionado.tipo=='C' && filtroRapidoSelecionado.dias==null? 'color:green':''" >Concluida</a>
                                            </v-col>
                                    </v-row>

                                  </v-container>
                         </v-col>
                         <v-col cols="12" md="4">
                            <div style="">
                                <div style="display:flex;flex-direction:row;justify-content:space-between;padding:0px!important;padding-right:15px;padding-bottom:10px;">
                                    <div class="celularCampoForm" style="margin-top:10px;width:40%;padding:0px!important;">
                                            <v-btn block elevation="" large color="info" @click="listaAtividadesProjetos()" >Buscar</v-btn>
                                    </div>
                                    <div class="celularCampoForm" style="margin-top:10px;width:40%;padding:0px!important;">
                                            <v-btn block elevation="" large color="warning" @click="limpar()" >Limpar</v-btn>
                                    </div>
                                </div>
                                <JsonCSV :data="atividadesProjetos" v-if="atividadesProjetos.length > 0" :name="nome_relatorio">
                                    <div style="background-color:#2b81d6;width:450px;height:40px;display:flex;align-items:center;justify-content:center;font-weight:bold;color:white;border-radius:5px;">
                                        Download Resultado 
                                    </div>
                                </JsonCSV>
                            </div>
                         </v-col>
                       </v-row>
                     </v-container>

    
                    
       
                </div>
                
            </v-card-text>
               
        </v-card>

        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="display:flex;justify-content:flex-end;padding:10px;">
                    <v-btn large elevation="" color="info" @click="novaAtividade()">Adicionar nova atividade</v-btn>
                </div>
                <div style="display:flex;justify-content:flex-end;padding:10px;">
                    <a @click="exportarComoTexto()">Exportar Como Texto</a>
                    <a style="margin-left:20px;color:green;" @click="verExportacao()">Ver Exportação</a>
                    <a style="margin-left:20px;color:red;" @click="limpaUltimaExportacaoTexto()">Limpar Ultima Exportarção Texto</a>
                    <a style="margin-left:20px;color:red;" @click="limpaTodaExportacaoTexto()">Limpar Toda a Exportação</a>
                </div>
                    
                    <template>
                        <v-data-table :headers="headers" :items="atividadesProjetos" item-key="idatividade" :items-per-page="2300" class="elevation-1" >

                            <template v-slot:[`item.nomeatividade`]="{ item }">
                                <div style="display:flex;flex-direction:row;width:100%;max-width:400px;word-break: break-all;">
                                    <div style="">
                                       <v-icon v-if="item.fl_concluida" color="success" :title="`Concluida em ${item.dt_concluida}`">mdi-checkbox-marked</v-icon>
                                    </div>
                                    <div style="">
                                       <v-icon v-if="!item.fl_concluida && item.nomeusuario" color="info" title="Atribuída mas não concluida" >mdi-account-hard-hat</v-icon>
                                    </div>
                                    <div style="">
                                       <v-icon v-if="!item.nomeusuario" color="error" title="Não Atribuida" >mdi-cancel</v-icon>
                                    </div>
                                    <div style="display:flex;flex-direction:column;">
                                        <div style="font-weight:bold;">{{item.nomeatividade}} <span v-if="item.nomeusuario" style="color:blue">&nbsp;{{item.nomecliente}}</span> </div>
                                        <!-- <div>{{item.descricao}}</div> -->
                                        <div v-html="item.descricao" ></div>
                                        <div><b>Rep:</b>{{item.repositorio}} - <b>Branch:</b> {{item.branch}}</div>
                                       
                                    </div>
                                </div>
                            </template> 

                            <template v-slot:[`item.testado`]="{ item }">
                                <div style="">
                                    <div style="">
                                       <v-icon v-if="item.testado" color="success" :title="``">mdi-check</v-icon>
                                    </div>
                                </div>
                            </template> 

                            <template v-slot:[`item.producao`]="{ item }">
                                <div style="">
                                    <div style="">
                                       <v-icon v-if="item.producao" color="success" :title="``">mdi-check</v-icon>
                                    </div>
                                </div>
                            </template> 

                            

                            <template v-slot:[`item.idatividadeaux`]="{ item }">
                                <v-btn small elevation="" class="ml-2" @click="setExibirModalConfiguracoes(item)" color="info">
                                    <v-icon color="">mdi-cog-outline</v-icon>
                                </v-btn>
                            </template> 

                            
                            
                        
                        </v-data-table>
                    </template>


            </v-card-text>
               
        </v-card>







            <v-dialog v-model="exibirModalConfiguracoes" width="80vw" style="max-height:90vh;height:90vw;"  >

                    <div style="display:flex;flex-direction:row;width:100%;max-height:80vh;height:80vw;">
                    
                
                            <div style="background-color:white;width:60%;display:flex;flex-direction:column;border-right:1px solid #b1b1b1;max-height:80vh;height:80vw;">
                                <div>
                                    <v-container class="pa-8">
                                        <v-row>

                                            <v-col cols="12" md="12" v-if="atividadeselecionada">
                             
                                                    <v-container class="">

                                                        <v-row style="padding-top:0px!important;padding-bottom:0px!important;">
                                                            <v-col  cols="12" md="4" style="font-weight:bold;padding-top:0px!important;padding-bottom:0px!important;" >Projeto</v-col>
                                                            <v-col  cols="12" md="4" style="font-weight:bold;padding-top:0px!important;padding-bottom:0px!important;" >Responsavel</v-col>
                                                            <v-col  cols="12" md="4" style="font-weight:bold;padding-top:0px!important;padding-bottom:0px!important;" >Dt. Est. Entrega</v-col>
                                                        </v-row>
                                                        <v-row style="padding-top:0px!important;padding-bottom:0px!important;">
                                                            <v-col cols="12" md="4" style="padding-top:0px!important;padding-bottom:0px!important;" >{{atividadeselecionada.nomeprojeto}}</v-col>
                                                            <v-col cols="12" md="4" style="padding-top:0px!important;padding-bottom:0px!important;" >
                                                                <a v-if="!exibirAlterarResponsavel" @click="setExibirAlterarResponsavel()" style="font-weight:bold;">{{atividadeselecionada.nomeusuario}}</a>
                                                                <v-select v-if="exibirAlterarResponsavel" color v-model="idResponsavelTroca" :items="listaUsuarios"  item-text="nome" item-value="id" id="alterarResp" name="alterarResp" return-object
                                                                    @change="setExibirAlterarResponsavel()" hide-details style="height:10px;padding-top:0px;margin-top:0px;"></v-select>
                                                            </v-col>
                                                            <v-col cols="12" md="4" style="padding-top:0px!important;padding-bottom:0px!important;" >
                                                                <a v-if="!exibirAlterarDataEstimativa" @click="setExibirAlterarDataEstimativa()" style="font-weight:bold;">{{atividadeselecionada.dt_estimativa}}</a>
                                                                <v-text-field id="alterarDtEst" name="alterarDtEst" autofocus v-if="exibirAlterarDataEstimativa" type="date" @blur="setExibirAlterarDataEstimativa()"  @change="setExibirAlterarDataEstimativa()" v-model="atividadeselecionada.dt_estimativa_ing" style="height:10px;padding-top:0px;margin-top:0px;" hide-details></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row style="padding-top:20px!important;padding-bottom:0px!important;">
                                                            <v-col cols="12" md="4" style="padding-top:0px!important;padding-bottom:0px!important;" ></v-col>
                                                            <v-col cols="12" md="4" style="padding-top:0px!important;padding-bottom:0px!important;" >
                                                                <v-text-field autofocus id="campo-titulo" @change="atualizarAtividade()" type="text" label="Repositório"  v-model="atividadeselecionada.repositorio" outlined return-object hide-details dense>
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" md="4" style="padding-top:0px!important;padding-bottom:0px!important;" >
                                                                <v-text-field autofocus id="campo-titulo" @change="atualizarAtividade()" type="text" label="Branch"  v-model="atividadeselecionada.branch" outlined return-object hide-details dense>
                                                                </v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row  style="padding-top:0px;padding-bottom:0px;margin-top:30px;">
                                                            <v-col  cols="12" md="2" style="font-weight:bold;padding-top:0px;padding-bottom:0px;" >Atividade</v-col>
                                                        </v-row>
                                                        <v-row  style="padding-top:0px;padding-bottom:0px;">
                                                            <v-col cols="12" md="10" style="padding-top:0px;padding-bottom:0px;" >{{atividadeselecionada.nomeatividade}}</v-col>
                                                        </v-row>
                                                        <v-row style="padding-top:0px;padding-bottom:0px;margin-top:40px;">
                                                            <v-col  cols="12" md="2" style="font-weight:bold;padding-top:0px;padding-bottom:0px;" >Descrição</v-col>
                                                        </v-row>
                                                        <v-row style="padding-top:0px;padding-bottom:0px;">
                                                            <v-col cols="12" md="10" style="padding-top:0px;padding-bottom:0px;" >
                                                                <div v-html="atividadeselecionada.descricao"></div>
                                                                
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>

                                            </v-col>

                                            
                                        
                                        

                                        </v-row>
                                    </v-container>
                                </div>
                                <div>
                                    <v-container class="pa-8" style="padding-top:1px!important;">
                                        <v-row>

                                            <v-col cols="12" md="12">Novo Movimento
                                                    <v-container class="">
                                                    <v-row>
                                                        <v-col  cols="12" md="12" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                            <v-textarea height="50" outlined color="" hide-details placeholder="" error-count="" name="" label="" v-model="movimento.descricao" style="margin:0px!important;padding:0px!important;" ></v-textarea> 
                                                        </v-col>
                                                    </v-row>
                                                    <v-row style="margin-top:20px;">
                                                        <v-col  cols="12" md="3" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;" >
                                                            <v-text-field type="number" filled label="Horas" v-model="movimento.tempo_gasto" name="texto" hide-details ></v-text-field>
                                                        </v-col>
                                                        <v-col  cols="12" md="4" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;display:flex;justify-content:flex-end;margin-top:20px;" >
                                                            <v-btn color="info" @click="registrarMovimento(3)"  hide-details>Só Movimentar</v-btn >
                                                        </v-col>
                                                        <v-col  cols="12" md="5" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;display:flex;justify-content:flex-end;margin-top:20px;" >
                                                            <v-btn color="success" @click="finalizarMovimentar(4)" hide-details >Movimentar e Concluir</v-btn >
                                                        </v-col>
                                                    </v-row>
                                                   
                                                    <v-row v-if="atividadeselecionada" style="margin-top:5px;display:flex;align-items:flex-end;justify-content:flex-end;">
                                                        <v-col  cols="12" md="4" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;display:flex;justify-content:flex-end;margin-top:20px;" >
                                                            <v-checkbox label="Testado"  @change="setMarcadoComoTeste()"   v-model="atividadeselecionada.testado"    hide-details></v-checkbox>
                                                        </v-col>
                                                        <v-col  cols="12" md="5" style="margin:0px!important;padding:0px!important;margin-bottom:0px!important;padding-bottom:0px!important;display:flex;justify-content:flex-end;margin-top:20px;" >
                                                            <v-checkbox label="Em Produção"  @change="setMarcadoEmProducao()" v-model="atividadeselecionada.producao"  hide-details></v-checkbox>
                                                        </v-col>
                                                    </v-row>
                                                   
                                                    </v-container>
                                                        
                                            </v-col>
                                            
                                            
                                        
                                        

                                        </v-row>
                                    </v-container>
                                </div>
                                
                            </div>

                            <div style="background-color:#efefef;width:40%;display:flex;flex-direction:column;padding:10px;max-height:80vh;height:80vw;overflow:auto;padding-bottom:20px!important;">

                                <v-container class="pa-2" style="max-height:80vh;padding-bottom:20px!important;">
                                    <v-card class="" style="margin-bottom:10px;" elevation="" max-width="" v-for="item in movimentos" :key="item.id" :dot-color="item.color" >
                                        <v-card-title style="font-size:16px;">{{item.desctipomovimento}}</v-card-title>
                                        <v-card-text style="font-size:16px;">
                                            <div><b>Descrição:</b></div>
                                            <div v-html="item.descricao"></div>
                                            <br>
                                            <div><b>Horas Gastas:</b> {{item.tempo_gasto}}</div>
                                        </v-card-text>
                                        <v-card-text style="display:flex;flex-direction:column;justify-content:flex-end;align-items:flex-end;font-weight:600;color:#817373;">
                                            <div>{{item.datamovimento}} as {{item.horamovimento}}</div> 
                                            <div>{{item.nomeusuario}}</div> 
                                        </v-card-text>
                                    
                                    </v-card>
                                </v-container>

                                
                            </div>

                    </div>

            </v-dialog>


                
                <v-dialog v-model="exibirModalCriarAtividade" width="80vw" max-width="1000px">
                    <div style="background-color:white;width:100%;display:flex;flex-direction:column;">
                        <div>
                            <v-container class="pa-8">

                                <v-row>
                                        <v-col cols="12" md="12">
                                                <v-text-field autofocus id="campo-titulo" type="text" label="Titulo"  v-model="atividadeCriar.titulo" outlined return-object hide-details dense>
                                                </v-text-field>
                                        </v-col>

                                        <v-col cols="12" md="12">
                                                <!-- <v-textarea label="Descrição"  v-model="atividadeCriar.descricao" outlined return-object hide-details dense >
                                                </v-textarea>  -->
                                                <label for="descricao">Descrição</label>
                                                <vue-editor id="descricao" v-model="atividadeCriar.descricao"></vue-editor>
                                        </v-col>

                                        <v-col class="d-flex" cols="12" sm="4" >
                                            <v-autocomplete
                                                label="Selecionar Projeto"
                                                :items="selectPrioridade"
                                                v-model="atividadeCriar.prioridade"
                                                item-text="titulo" 
                                                item-value="id"
                                            ></v-autocomplete>
                                            
                                        </v-col>

                                        <v-col class="d-flex" cols="12" sm="4" >
                                            <v-autocomplete
                                                label="Tipo"
                                                :items="tipos"
                                                v-model="atividadeCriar.tipo" 
                                            ></v-autocomplete>
                                            
                                        </v-col>

                                        <v-col class="d-flex" cols="12" sm="4" >
                                            <v-autocomplete
                                                label="Cliente"
                                                :items="clientes"
                                                v-model="atividadeCriar.idcliente"
                                                item-text="nome" 
                                                item-value="id"
                                            ></v-autocomplete>
                                            
                                        </v-col>


                                        <v-col cols="4">
                                                <v-text-field type="date" label="Data para entrega" v-model="diaselecionado" outlined return-object hide-details dense>
                                                </v-text-field>
                                        </v-col>

                                        <v-col cols="4">
                                                <v-text-field type="number" label="Horas estimadas" v-model="atividadeCriar.horas_estimadas" outlined dense hide-details >
                                                </v-text-field>
                                        </v-col>

                                        <v-col>
                                            <v-autocomplete
                                                label="Usuario Implantador"
                                                :items="listadeusuarios"
                                                v-model="usuarioAtribuirAtividade"
                                                item-text="nome" 
                                                item-value="id"
                                                outlined return-object hide-details dense
                                            ></v-autocomplete>
                                            
                                    </v-col>

                                    <v-col cols="6">
                                            <v-text-field type="text" label="Repositorio" v-model="atividadeCriar.repositorio" outlined return-object hide-details dense>
                                            </v-text-field>
                                    </v-col>

                                    <v-col cols="6">
                                            <v-text-field type="text" label="Branch" v-model="atividadeCriar.branch" outlined dense hide-details >
                                            </v-text-field>
                                    </v-col>
                                    
                                    <v-col cols="4">
                                        <v-checkbox label="Marcada como concluida" v-model="ativiadeConcluida"></v-checkbox>
                                    </v-col>

                                        <v-col cols="12" md="12">
                                            
                                            <v-row>
                                                    <v-col>
                                                        <v-btn elevation="" @click="() => registrarAtividade()" color="info" block>Registrar</v-btn> 
                                                    </v-col>
                                                    <v-col>
                                                        <v-btn elevation="" @click="() => exibirModalCriarAtividade=false" color="error" block> Cancelar</v-btn> 
                                                    </v-col>
                                            </v-row>

                                        </v-col>

                                </v-row>
                                

                            </v-container>
                        </div>
                        
                    </div>
                </v-dialog>

                <v-dialog v-model="modalExportarComoTexto" width="80vw" max-width="1000px" height="600px" >
                    <div style="background-color:white;width:100%;display:flex;flex-direction:column;padding:40px;height:800px;overflow:auto;">
                        <div v-for="linha in linhasExportadasComoTexto" :key="linha.id" style="margin-bottom:30px;" >
                                *{{linha.usuario}}*
                                <div v-for="atv in linha.linhas" :key="atv.id" >
                                    <!-- {{atv}} -->
                                    <div>- {{atv.cab}} <span v-if="atv.nomecliente">({{atv.nomecliente}})</span></div>
                                    <div style="display:flex;flex-direction:row;">
                                        <div style="" v-if="atv.desc != '.' && atv.desc != '' && atv.desc?.length >= 12" v-html="('<p>&nbsp;&nbsp;&nbsp;&nbsp;'+atv.desc.substring(3))"></div>
                                    </div>
                                </div>
                        </div>
                        
                    </div>
                </v-dialog>

    </div>

    
</template>

<script>


// import sistemas from '@/services/sistema.service.js'
import usuarios from '@/services/usuario.service.js'
import projetos from '@/services/prioridades.service.js'
import JsonCSV from 'vue-json-csv'
import Prioridades from '@/services/prioridades.service.js';
import Clientes from '@/services/cliente.service.js'
import AtividadeServico from '@/services/atividade.service.js'
import {alertar} from '@/services/notificacao.service.js';
import UsuarioServico from '@/services/usuario.service.js';
import SolicitacaoServico from '@/services/solicitacao.service.js'
import { VueEditor } from 'vue2-editor'


import dayjs from 'dayjs'

export default {
    name:'Atividades',
    components: {
        JsonCSV,
        VueEditor
    },
    data(){
        return{
            diaselecionado:null,
            idatividadefiltro:null,
            idsolicitacaofiltro:null,
            linhasExportadasComoTexto:[],
            modalExportarComoTexto:false,
            idResponsavelTroca:null,
            atividades_testadas:false,
            atividades_producao:false,
            items: [
                { id: 1, color: 'info', icon: 'mdi-information', },
                { id: 2, color: 'error', icon: 'mdi-alert-circle', },
                { id: 3, color: 'error', icon: 'mdi-alert-circle', },
                { id: 4, color: 'error', icon: 'mdi-alert-circle', },
                { id: 5, color: 'error', icon: 'mdi-alert-circle', },
                { id: 6, color: 'error', icon: 'mdi-alert-circle', },
                { id: 7, color: 'error', icon: 'mdi-alert-circle', },
                { id: 8, color: 'error', icon: 'mdi-alert-circle', },
                { id: 9, color: 'error', icon: 'mdi-alert-circle', },
            ],
            movimentos:[],
            novaObservacao:null,
            exibirModalConfiguracoes:false,
            nome_relatorio:'relatorio.csv',
            dt_concluida_inicial:null,
            dt_concluida_final:null,
            horario_inicial:null,
            dt_estimativa_inicial:null,
            dt_estimativa_final:null,
            idprojeto:null,
            listaProjetos:[],
            idstatusprojeto:{id:3,titulo:'Não Concluidos'},
            listaStatusProjetos:[
                {id:1,titulo:'Todos'},
                {id:2,titulo:'Concluidos'},
                {id:3,titulo:'Não Concluidos'},
            ],
            idusuario:null,
            listaUsuarios:[
                {id:1,nome:'Todos'}
            ],
            atribuida:2,
            listaAtribuidas:[
                {id:1,nome:'Todos'},
                {id:2,nome:'Atribuidas'},
                {id:3,nome:'Não Atribuidas'},
            ],
            concluido:3,
            listaStatusConcluido:[
                {id:1,nome:'Todos'},
                {id:2,nome:'Concluído'},
                {id:3,nome:'Não Concluído'},
            ],
            headers: [
                        { text: 'Id', align: 'start', sortable: true, value: 'idatividade',},
                        { text: 'Sol.', align: 'start', sortable: true, value: 'idsolicitacao',},
                        { text: 'Projeto', align: 'start', sortable: true, value: 'nomeprojeto',},
                        { text: 'Responsavel', value: 'nomeusuario',sortable: true, },
                        // { text: 'Cliente', value: 'nomecliente',sortable: true, },
                        { text: 'Atividade', value: 'nomeatividade',width:"500px",sortable: true, },
                        { text: 'Estimativa Entrega', value: 'dt_estimativa',sortable: true, },
                        { text: 'Concluida', value: 'dt_concluida',sortable: true, },
                        // { text: 'Testado', value: 'testado',sortable: true, },
                        // { text: 'Producao', value: 'producao',sortable: true, },
                        { text: 'Custo', value: 'custo',sortable: true, },
                        { text: 'Valor', value: 'valor',sortable: true, },
                        { text: 'Hrs Est.', value: 'horas_estimadas',sortable: true, },
                        { text: 'Hrs Trab.', value: 'qtdehorastrabalhadas',sortable: true, },
                        { text: 'Ação', value: 'idatividadeaux',sortable: true, },
                    ],
            atividadesProjetos:[],
            atividadeselecionada:null,
            texto:null,
            exibirModalCriarAtividade:false,
            atividadeCriar: { id:null, titulo:null, descricao:null, tipo:null, prioridade:null, idimplantacao:null, dt_entrega:null, idusuario: null, horas_estimadas: null,idcliente:0 },
            movimento:{idatividade:null,datahoramovimento:null,descricao:null,idusuario:null,tipomovimento:null,tempo_gasto:null},
            selectPrioridade:[],
            tipos: ['Atendimento Cliente','Bug', 'Nova Funcionalidade', 'Testes', 'Meets'],
            filtroTipos: ['Atendimento Cliente','Bug', 'Nova Funcionalidade', 'Testes', 'Meets'],
            clientes:[],
            clienteSelecionado:0,
            listadeusuarios:[],
            usuario:null,
            exibirAlterarResponsavel:false,
            exibirAlterarDataEstimativa:false,
            filtroRapidoSelecionado:{},
            ativiadeConcluida: false,
            filtroProjeto: null,
            searchQueryProjetos: '',
            searchQueryTipos: '',
            usuarioAtribuirAtividade:null,
        }
    },
    watch: {
        
    },
    methods:{

            filtrarProjetos() {
                const filtro = this.selectPrioridade.filter(option => {
                return option.titulo.toLowerCase().includes(this.searchQueryProjetos.toLowerCase())
            })
                this.filtroProjeto = filtro
                this.atividadeCriar.prioridade = filtro[0];
            },

            filtrarTipos() {
                const filtro = this.tipos.filter(option => {
                return option.toLowerCase().includes(this.searchQueryTipos.toLowerCase())
            })
                this.filtroTipos = filtro
                this.atividadeCriar.tipo = filtro[0];
            },

            async setMarcadoComoTeste(){

                let obj = {}
                let objAux = {
                                idatividade: this.atividadeselecionada.idatividade,
                                datahoramovimento: dayjs().format(),
                                idusuario: localStorage.getItem('idusuario'),
                            }

                if(this.atividadeselecionada.testado === true){
                    obj = { id:this.atividadeselecionada.idatividade,idstatus:4,testado:true }
                    objAux.descricao = 'Atividade marcada como Testada'
                    objAux.tipomovimento = 7
                    objAux.idusuario = localStorage.getItem('idusuario')
                }else{
                    obj = { id:this.atividadeselecionada.idatividade,idstatus:1,testado:false }
                    objAux.descricao = 'Atividade retirada do Status de Testada'
                    objAux.tipomovimento = 9
                    objAux.idusuario = localStorage.getItem('idusuario')
                }

                await AtividadeServico.atualizar(obj).then(async () => { 
                    
                                AtividadeServico.registrarMovimento(objAux).then(()=> this.buscarMovimentos())

                        }).catch(error=>{
                            alert('Não foi Possível Registrar!1')
                            console.log(error)
                        })
            },
            async setMarcadoEmProducao(){

                let obj = {}
                let objAux = {
                                idatividade: this.atividadeselecionada.idatividade,
                                datahoramovimento: dayjs().format(),
                                idusuario: localStorage.getItem('idusuario'),
                            }

                if(this.atividadeselecionada.producao === true){
                    obj = { id:this.atividadeselecionada.idatividade,idstatus:5,producao:true }
                    objAux.descricao = 'Atividade marcada como Enviada para Produção'
                    objAux.tipomovimento = 8
                    objAux.idusuario = localStorage.getItem('idusuario')
                }else{
                    obj = { id:this.atividadeselecionada.idatividade,idstatus:4,producao:false }
                    objAux.descricao = 'Atividade retirada da Enviada para Produção'
                    objAux.tipomovimento = 10
                    objAux.idusuario = localStorage.getItem('idusuario')
                }

                await AtividadeServico.atualizar(obj).then(async () => { 
                                AtividadeServico.registrarMovimento(objAux).then(()=> this.buscarMovimentos())
                        }).catch(error=>{
                            alert('Não foi Possível Registrar!2')
                            console.log(error)
                        })
            },
            async atualizarAtividade(){
                this.atividadeselecionada.id = this.atividadeselecionada.idatividade      
                await AtividadeServico.atualizar(this.atividadeselecionada).then(async () => { 
                            alert('Atividade Atualizada')
                        }).catch(error=>{
                            alert('Não foi Possível Registrar!3')
                            console.log(error)
                        })      
            },
            async setExibirAlterarDataEstimativa(){
                this.exibirAlterarDataEstimativa = !this.exibirAlterarDataEstimativa
                
                if(this.exibirAlterarDataEstimativa){
                    
                    document.getElementById('alterarDtEst').focus()
                    
                }else{
                   
                        this.atividadeselecionada.dt_estimativa = dayjs(this.atividadeselecionada.dt_estimativa_ing).format('YYYY-MM-DD')
                        this.atividadeselecionada.dt_entrega = dayjs(this.atividadeselecionada.dt_estimativa_ing).format('YYYY-MM-DD')
                        this.atividadeselecionada.id = this.atividadeselecionada.idatividade
                        await AtividadeServico.atualizar(this.atividadeselecionada).then(async () => { 
                            console.log("ok")
                        }).catch(error=>{
                            alert('Não foi Possível Registrar!3')
                            console.log(error)
                        })

                }
            },
            async setExibirAlterarResponsavel(){
                this.exibirAlterarResponsavel = !this.exibirAlterarResponsavel
                
                if(this.exibirAlterarResponsavel){
                    document.getElementById('alterarResp').focus()
                }else{
                        // alert(JSON.stringify(this.idResponsavelTroca,null,2))
                        this.atividadeselecionada.idresponsavel = this.idResponsavelTroca.id
                        this.atividadeselecionada.nomeresponsavel = this.idResponsavelTroca.nome
                        this.atividadeselecionada.id = this.atividadeselecionada.idatividade
                        await AtividadeServico.atualizar(this.atividadeselecionada).then(async () => { 
                            this.idResponsavelTroca = null
                            this.exibirModalConfiguracoes = false
                            this.listaAtividadesProjetos()
                            console.log("ok")
                        }).catch(error=>{
                            this.idResponsavelTroca = null
                            alert('Não foi Possível Registrar!')
                            console.log(error)
                        })

                }
            },
            async setarFiltro(dados){

                this.filtroRapidoSelecionado = dados

                if(dados.tipo == 'P'){
                    if(dados.dias !== null){
                        this.dt_estimativa_inicial  = dayjs().subtract(dados.dias,'days').format('YYYY-MM-DD')
                        this.dt_estimativa_final  = dayjs().format('YYYY-MM-DD')
                    }else{
                        this.dt_estimativa_inicial  = null
                        this.dt_estimativa_final    = null
                    }
                    
                    this.atribuida = 2
                    this.concluido = 3

                    this.idstatusprojeto = {id:3,titulo:'Não Concluidos'}

                    //limpar
                    this.dt_concluida_inicial  = null
                    this.dt_concluida_final    = null
                    this.horario_inicial    = null
                }

                 if(dados.tipo == 'C'){
                    if(dados.dias !== null){
                        this.dt_concluida_inicial  = dayjs().subtract(dados.dias,'days').format('YYYY-MM-DD')
                        this.dt_concluida_final  = dayjs().format('YYYY-MM-DD')
                    }else{
                        this.dt_concluida_inicial  = null
                        this.dt_concluida_final    = null
                        this.horario_inicial    = null
                    }

                    this.idstatusprojeto = {id:1,titulo:'Todos'},

                    this.atribuida = 2
                    this.concluido = 2


                    //limpar
                    this.dt_estimativa_inicial  = null
                    this.dt_estimativa_final    = null
                }
                
                // this.dt_concluida_final
            },
            async buscarMovimentos(){

                    await AtividadeServico.buscarMovimento({idatividade:this.atividadeselecionada.idatividade}).then(async (resp) => { 

                        let movimentos = resp.data
                        movimentos = movimentos.sort(function (a, b) {
                            if (a.datahoramovimento > b.datahoramovimento) {
                                return -1;
                            }
                            if (a.datahoramovimento < b.datahoramovimento) {
                                return 1;
                            }
                            return 0;
                        })
                        this.movimentos = movimentos

                    }).catch(error=>{
                        alert('Não foi Possível Registrar!4')
                        console.log(error)
                    })

            },
            async registrarAtividade(){
        
                if(this.atividadeCriar.titulo){
                    this.atividadeCriar.idusuario = localStorage.getItem('idusuario');
                    this.atividadeCriar.idresponsavel = this.usuarioAtribuirAtividade.id
                    this.atividadeCriar.dt_entrega = this.diaselecionado;

                    if(!this.atividadeCriar.dt_entrega){
                        this.atividadeCriar.dt_entrega = dayjs().format('YYYY-MM-DD')
                    }

                    delete this.atividadeCriar.ultimaobs;
                    console.log(this.atividadeCriar);
                    if (this.ativiadeConcluida && !this.atividadeCriar.horas_estimadas) {
                        console.log('entrou aqui >>>>>>')
                        alertar('info', '', 'Preencha o campo de horas estimadas!')
                        return
                    }

                    if (this.ativiadeConcluida && !this.atividadeCriar.descricao) {
                        console.log('entrou aqui >>>>>>')
                        alertar('info', '', 'Preencha o campo de descrição!')
                        return
                    }
                    await AtividadeServico.criar(this.atividadeCriar).then(async (resp) => { 
                        console.log("registrarAtividade resp ",resp)
                        alertar('success', '=)', 'Atividade registrada com sucesso!')
                        if  (this.ativiadeConcluida) {
                            this.atividadeselecionada = {
                                idatividade: resp.data[0],
                                idcliente: this.atividadeCriar.idcliente
                            }
                            this.movimento.tempo_gasto = this.atividadeCriar.horas_estimadas;
                            this.movimento.descricao = this.atividadeCriar.descricao;
                            await this.finalizarMovimentar(4)
                        }
                        this.exibirModalCriarAtividade=false 
                        this.atividadeCriar={ id:null, titulo:null, descricao:null, idimplantacao:null, dt_entrega: null, idusuario: null }
                        await this.listaAtividadesProjetos()

                        
                    }).catch(error=>{
                        alert('Não foi Possível Registrar!5 ')
                        alert(error)
                        console.log(error)
                        this.exibirModalCriarAtividade=false
                    })
                }else{
                    alert("Titulo Obrigatório!")
                }

            },
            async registrarMovimento(tipo){
        
                if(this.movimento.descricao){

                    this.movimento.idusuario = localStorage.getItem('idusuario');
                    this.movimento.tipomovimento = tipo;
                    this.movimento.idatividade = this.atividadeselecionada.idatividade
                    this.movimento.idcliente = this.atividadeselecionada.idcliente
                    this.movimento.datahoramovimento = dayjs().format()

                    await AtividadeServico.registrarMovimento(this.movimento).then(async () => { 
                        this.movimento = {idatividade:null,datahoramovimento:null,descricao:null,idusuario:null,tipomovimento:null,tempo_gasto:null}
                        this.buscarMovimentos()
                    }).catch(error=>{
                        alert('Não foi Possível Registrar!6')
                        console.log(error)
                        this.exibirModalCriarAtividade=false
                    })
                }else{
                    alert("Titulo Obrigatório!")
                }

            },
            async concluirSolicitacao(dados){
                await SolicitacaoServico.concluirSolicitacao(dados).then(async (resp) => { 
                        console.log("registrarAtividade resp ",resp)
                        alertar('success', '=)', 'Atividade concluida com sucesso!')
                    }).catch(error=>{
                        alert('Não foi Possível Registrar! ')
                        alert(error)
                        console.log(error)
                        this.exibirModalCriarAtividade=false
                    })
            },
            async marcarConcluida(atividade){

                    atividade.usuario ={id:localStorage.getItem('idusuario')}
                    atividade.id = atividade.idatividade

                    // alert(JSON.stringify(atividade,null,2))

                    await AtividadeServico.marcarConcluida(atividade).then(async () => { 
                        await this.buscarMovimentos()
                        await this.listaAtividadesProjetos()

                        if(atividade.idsolicitacao > 0){
                            let objsol = {
                                    id:atividade.idsolicitacao,
                                    fl_concluida:true,
                                    dt_concluida:dayjs().format()
                                }
                            await this.concluirSolicitacao(objsol)     
                            this.exibirModalConfiguracoes=false
                            alertar('success', '=D', 'Concluida com Sucesso!')
                        }else{
                            this.exibirModalConfiguracoes=false
                            alertar('success', '=D', 'Concluida com Sucesso!')
                        }


                    }).catch(error=>{
                        alert('Não foi Possível Registrar! 7')
                        console.log(error)
                        this.exibirModalConfiguracoes=false
                    })

            },
            async finalizarMovimentar(tipo){


                if(this.movimento.descricao){

                    this.movimento.idusuario = localStorage.getItem('idusuario');
                    this.movimento.tipomovimento = tipo;
                    this.movimento.idatividade = this.atividadeselecionada.idatividade
                    this.movimento.datahoramovimento = dayjs().format()
                    this.movimento.idcliente = this.atividadeselecionada.idcliente
                    console.log(this.movimento.idcliente);
                    if(!this.movimento.tempo_gasto){
                        alertar('warning', '=/', 'Para Concluir informe um tempo gasto')
                    }else{

                        await AtividadeServico.registrarMovimento(this.movimento).then(async () => { 

                            if(this.atividadeselecionada && this.atividadeselecionada.idsolicitacao > 0 && this.atividadeselecionada.emailusuariosolicitacao ){
                                let objetoEmal = {
                                    destinatario:this.atividadeselecionada.emailusuariosolicitacao,
                                    assunto:`Atividade Concluida: id: ${this.atividadeselecionada.id} gerada da solicitacao: ${this.atividadeselecionada.idsolicitacao} `,
                                    html:this.atividadeselecionada.nomeatividade+'. <br><br> descrição:'+this.atividadeselecionada.descricao
                                }
                                SolicitacaoServico.enviarEmail(objetoEmal)
                            }
                            
                            this.movimento = {idatividade:null,datahoramovimento:null,descricao:null,idusuario:null,tipomovimento:null,tempo_gasto:null}
                            this.marcarConcluida(this.atividadeselecionada)
                        }).catch(error=>{
                            alert('Não foi Possível Registrar!8')
                            console.log(error)
                            this.exibirModalCriarAtividade=false
                        })
                    }

                }else{
                    alert("Titulo Obrigatório!")
                }

            },
            async buscarUsuarios(){
                this.listadeusuarios = await UsuarioServico.buscar({tipo:1}).then(res => res.data)
                let indexUsuarioFiltrado = this.listadeusuarios.findIndex(x =>{ return x.id == localStorage.getItem('idusuario')})
                this.usuario = this.listadeusuarios[indexUsuarioFiltrado]
                if(!this.usuario){
                    this.usuario = this.listadeusuarios[0]
                }
            },
            async buscarClientes(){
                await Clientes.buscar({ativo:true}).then(res =>{
                    this.clientes = [{id:0,nome:'Sem Cliente'}].concat(res.data)
                })
            },
            async buscarPrioridade(){

                await Prioridades.buscarPrioridade() .then((res)=>{
                    
                    const {data} = res;
                    data.forEach((e) => {
                        const prioridade = { id: e.id, titulo: e.titulo }
                        this.selectPrioridade = [...this.selectPrioridade, prioridade]
                    })

                    this.selectPrioridade.sort(function (a, b) {
                        if (a.titulo > b.titulo) {
                            return 1;
                        }
                        if (a.titulo < b.titulo) {
                            return -1;
                        }
                        return 0;
                    });
                    
                    this.filtroProjeto = this.selectPrioridade

                    const filter = data.filter((prioridade) => prioridade.status !== false);
                    this.prioridades = filter;
                    this.listadeprojetosFiltro = filter;

                })
                .catch(()=>{

                })
            },
            novaAtividade() {
                this.atividadeCriar={ id:null, titulo:null, descricao:null, tipo:null, prioridade:null, idimplantacao:null, dt_entrega:null, idusuario: null, horas_estimadas: null,idcliente:0 }
                this.exibirModalCriarAtividade = true;
                document.getElementById('campo-titulo').focus();
            },
            setExibirModalConfiguracoes(item){
                this.exibirModalConfiguracoes = !this.exibirModalConfiguracoes
                this.atividadeselecionada = item

                if(this.exibirModalConfiguracoes && this.atividadeselecionada){
                    this.buscarMovimentos()
                }
            },
            limpar(){
                this.dt_concluida_inicial = null
                this.dt_concluida_final = null
                this.horario_inicial = null
                this.dt_estimativa_inicial = null
                this.dt_estimativa_final = null
                this.idprojeto = 1
                this.usuario = 1
                this.atribuida = 1
                this.concluido = 1
                this.texto = null
                this.idsolicitacaofiltro = null
                this.idatividadefiltro   = null
                this.filtroRapidoSelecionado = {}
            },
            async listaAtividadesProjetos(){
                let parametros = {}
                if(this.idusuario){

                        this.atividadesProjetos = []
                        parametros.idprojeto = this.idprojeto
                        parametros.atribuida = this.atribuida
                        parametros.concluido = this.concluido
                        parametros.idusuario = this.idusuario
                        parametros.dt_concluida_inicial = this.dt_concluida_inicial
                        parametros.dt_concluida_final = this.dt_concluida_final
                        parametros.horario_inicial = this.horario_inicial
                        parametros.dt_estimativa_inicial = this.dt_estimativa_inicial
                        parametros.dt_estimativa_final = this.dt_estimativa_final
                        parametros.testado = this.atividades_testadas
                        parametros.producao = this.atividades_producao
                        parametros.texto = this.texto
                        parametros.idsolicitacaofiltro = this.idsolicitacaofiltro
                        parametros.idatividadefiltro = this.idatividadefiltro


                        this.nome_relatorio = dayjs().format('YYMMDDHHmmss')

                        let projetofiltro = this.listaProjetos.find( x => { if ( x.id == this.idprojeto){ return x } })
                        if(projetofiltro && projetofiltro.titulo){
                            this.nome_relatorio += "_"+ projetofiltro.titulo
                        }

                        let usuariofiltro = this.listaUsuarios.find( x => { if ( x.id == this.idusuario){ return x } })
                        if(usuariofiltro && usuariofiltro.nomeusuario){
                            this.nome_relatorio += "_usu_"+ usuariofiltro.nomeusuario
                        }

                        if(parametros.dt_concluida_inicial){
                            this.nome_relatorio += '_dt_conc_ini_'+dayjs(parametros.dt_concluida_inicial).format('DD-MM-YYYY')
                        }
                        if(parametros.dt_concluida_final){
                            this.nome_relatorio += '_dt_conc_fim_'+dayjs(parametros.dt_concluida_final).format('DD-MM-YYYY')
                        }
                        
                        if(parametros.dt_estimativa_inicial){
                            this.nome_relatorio += '_dt_est_ini_'+dayjs(parametros.dt_estimativa_inicial).format('DD-MM-YYYY')
                        }
                        if(parametros.dt_estimativa_final){
                            this.nome_relatorio += '_dt_est_fim_'+dayjs(parametros.dt_estimativa_final).format('DD-MM-YYYY')
                        }

                        if(parametros.concluido == 2){
                            this.nome_relatorio += '_concluida'
                        }
                        if(parametros.concluido == 3){
                            this.nome_relatorio += '_NAO_concluida'
                        }


                        this.nome_relatorio += '.csv'

                        this.atividadesProjetos = await projetos.buscarAtividadesProjetos(parametros).then(res => res.data)

                }
            },
            async listarProjetos(){
                let parametros = {ativo:true,idstatusprojeto:this.idstatusprojeto.id}
                // alert(JSON.stringify(parametros,null,2))
                // alert(JSON.stringify(this.idstatusprojeto,null,2))
                let listaproj = await projetos.buscarProjetos(parametros).then(res => res.data)
                
                this.listaProjetos = [ {id:1,titulo:'Todos'} ]
                for(let i=0, len=listaproj.length; i < len; i++){
                    this.listaProjetos.push(listaproj[i])
                }
                if(this.$route.params && this.$route.params.idprojeto){
                    this.idprojeto = this.$route.params.idprojeto
                }
            },
            async listarUsuarios(){
                let parametros = {ativo:true}
                let listausu = await usuarios.buscar(parametros).then(res => res.data)

                for(let i=0, len=listausu.length; i < len; i++){
                    this.listaUsuarios.push(listausu[i])
                }
               
               
                
            },

            verExportacao(){
                this.modalExportarComoTexto = true
            },

            limpaUltimaExportacaoTexto(){
                this.linhasExportadasComoTexto.pop()
            },

            limpaTodaExportacaoTexto(){
                this.linhasExportadasComoTexto = []
            },

            exportarComoTexto(){
                
                let registros = this.atividadesProjetos

                let nomeusuario = ''
                let linhas = []
                let objeto = {id:0,nome:'',linhas:[]}


                for(let a = 0,lena= registros.length; a < lena; a++){

                    let reg = registros[a]
                    // {
                    //     id:19,
                    //     nome:herlessi
                    //     linhas:[
                    //         {id:1,cab:'teste',desc:'teste'}
                    //         {id:2,cab:'teste',desc:'teste'}
                    //     ]
                    // }

                    

                    if(reg.nomeusuario != nomeusuario){

                        //se for a primeira vez
                        if(nomeusuario == ''){
                            //apenas atribuo dados de cabecalho ao objeto
                            objeto.id = reg.idusuario
                            objeto.usuario = reg.nomeusuario
                        }else{

                            //não é a primeira vez, então preciso atribuir o objeto ao linhasExportadasComoTexto antes de zerar
                            objeto.linhas = linhas
                            objeto.linhas.sort(function(a, b){return a.id - b.id})
                            this.linhasExportadasComoTexto.push(objeto)
                            objeto = {id:0,nome:'',linhas:[]}
                            //atribuo dados de cabecalho ao objeto
                            objeto.id = reg.idusuario
                            objeto.usuario = nomeusuario

                        }            

                        objeto.linhas.push({id:reg.idatividade,cab:reg.nomeatividade,desc:reg.descricao,nomecliente:reg.nomecliente})

                    }else{
                        objeto.linhas.push({id:reg.idatividade,cab:reg.nomeatividade,desc:reg.descricao,nomecliente:reg.nomecliente})
                    }

                }

                objeto.linhas = objeto.linhas.sort(function(a, b){return a.id > b.id?1:a.id < b.id?-1:0})
                this.linhasExportadasComoTexto.push(objeto)
                console.log(this.linhasExportadasComoTexto)


                this.modalExportarComoTexto = true
            },
            
    },


   

    
    async mounted(){

        let iduser = parseInt(localStorage.getItem('idusuario'))
        this.idusuario = iduser
        // this.dt_concluida_inicial = dayjs().format('YYYY-MM-DD')
        // this.dt_concluida_final = dayjs().format('YYYY-MM-DD')
        await this.buscarClientes()
        await this.buscarPrioridade()
        await this.buscarUsuarios()
        await this.listarUsuarios()
        await this.listarProjetos()
        await this.listaAtividadesProjetos()
        

    }
    
}
</script>

<style>

/* .v-dialog {
    position: absolute;
    bottom: 0; 
    right: 0;
} */

    .linhaFormulario{
        display: flex;
        flex-direction: row;
        /* background-color:red; */
        padding-left: 10%;
        padding-right: 10%;
    }

    .celularCampoForm{
        width:25%;
        padding:10px;
        /* background-color: green; */
    }

</style>